import { createRouter, createWebHistory } from "vue-router";
import Index from "@/views/Index.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: { title: "ページタイトル" },
    children: [
      {
        path: "",
        name: "Dashboard",
        meta: { title: "ダッシュボード" },
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/libraries/",
        name: "Libraries",
        meta: { title: "会員館一覧", role: ["admin"] },
        component: () => import("@/views/Libraries/Index.vue"),
      },
      {
        path: "/libraries/add/",
        name: "LibrariesAdd",
        meta: { title: "会員館登録", role: ["admin"] },
        component: () => import("@/views/Libraries/Add.vue"),
      },
      {
        path: "/libraries/detail/:id",
        name: "LibrariesDetail",
        meta: { title: "会員館詳細", role: ["admin"] },
        component: () => import("@/views/Libraries/Detail.vue"),
      },
      {
        path: "/libraries/edit/:id",
        name: "LibrariesEdit",
        meta: { title: "会員館編集", role: ["admin"] },
        component: () => import("@/views/Libraries/Edit.vue"),
      },
      {
        path: "/libraries/users/:id/add/",
        name: "LibrariesUserAdd",
        meta: { title: "会員館ユーザー登録", role: ["admin"] },
        component: () => import("@/views/Libraries/AddUser.vue"),
      },
      {
        path: "/libraries/users/:id/detail/:userId",
        name: "LibrariesUserDetail",
        meta: { title: "会員館ユーザー詳細", role: ["admin"] },
        component: () => import("@/views/Libraries/DetailUser.vue"),
      },
      {
        path: "/libraries/users/:id/edit/:userId",
        name: "LibrariesUserEdit",
        meta: { title: "会員館ユーザー編集", role: ["admin"] },
        component: () => import("@/views/Libraries/EditUser.vue"),
      },
      {
        path: "/libraries/import/",
        name: "LibrariesImport",
        meta: { title: "会員館一括読込", role: ["admin"] },
        component: () => import("@/views/Libraries/Import.vue"),
      },
      {
        path: "/information/",
        name: "information",
        meta: { title: "お知らせ一覧", role: ["admin"] },
        component: () => import("@/views/Information/Index.vue"),
      },
      {
        path: "/information/add/",
        name: "InformationAdd",
        meta: { title: "お知らせ登録", role: ["admin"] },
        component: () => import("@/views/Information/Add.vue"),
      },
      {
        path: "/information/detail/:id",
        name: "InformationDetail",
        meta: { title: "お知らせ詳細", role: ["admin"] },
        component: () => import("@/views/Information/Detail.vue"),
      },
      {
        path: "/information/edit/:id",
        name: "InformationEdit",
        meta: { title: "お知らせ編集", role: ["admin"] },
        component: () => import("@/views/Information/Edit.vue"),
      },
      {
        path: "/information/copy/:id",
        name: "InformationCopy",
        meta: { title: "お知らせコピー", role: ["admin"] },
        component: () => import("@/views/Information/Copy.vue"),
      },
      {
        path: "/users/",
        name: "Users",
        meta: { title: "ユーザー一覧", role: ["admin"] },
        component: () => import("@/views/Users/Index.vue"),
      },
      {
        path: "/users/add/",
        name: "UsersAdd",
        meta: { title: "ユーザー登録", role: ["admin"] },
        component: () => import("@/views/Users/Add.vue"),
      },
      {
        path: "/users/detail/:id",
        name: "UsersDetail",
        meta: { title: "ユーザー詳細", role: ["admin"] },
        component: () => import("@/views/Users/Detail.vue"),
      },
      {
        path: "/users/edit/:id",
        name: "UsersEdit",
        meta: { title: "ユーザー編集", role: ["admin"] },
        component: () => import("@/views/Users/Edit.vue"),
      },
      {
        path: "/change_settings/",
        name: "ChangeSettings",
        meta: { title: "個人設定変更" },
        component: () => import("@/views/ChangeSettings.vue"),
      },
      {
        path: "/change_password/",
        name: "ChangePassword",
        meta: { title: "パスワード変更" },
        component: () => import("@/views/ChangePassword.vue"),
      },
      {
        path: "/faqs/",
        name: "Faqs",
        meta: { title: "FAQ一覧", role: ["admin", "working_group"] },
        component: () => import("@/views/Faqs/Index.vue"),
      },
      {
        path: "/faqs/detail/:id",
        name: "FaqDetail",
        meta: { title: "FAQ詳細", role: ["admin", "working_group"] },
        component: () => import("@/views/Faqs/Detail.vue"),
      },
      {
        path: "/faqs/edit/:id",
        name: "FaqEdit",
        meta: { title: "FAQ編集", role: ["admin", "working_group"] },
        component: () => import("@/views/Faqs/Edit.vue"),
      },
      {
        path: "/faqs/add/",
        name: "FaqAdd",
        meta: { title: "FAQ登録", role: ["admin", "working_group"] },
        component: () => import("@/views/Faqs/Add.vue"),
      },
      {
        path: "/transitions/",
        name: "Transitions",
        meta: { title: "推移データ一覧" },
        component: () => import("@/views/Transitions/Index.vue"),
      },
      {
        path: "/transitions/detail/:id",
        name: "TransitionDetail",
        meta: { title: "推移データ詳細", role: ["admin"] },
        component: () => import("@/views/Transitions/Detail.vue"),
      },
      /* 以下モック */
      {
        path: "/surveys/",
        name: "Surveys",
        meta: { title: "調査票一覧" },
        component: () => import("@/views/Surveys/Index.vue"),
      },
      {
        path: "/surveys/add",
        name: "SurveysAdd",
        meta: { title: "調査票作成" },
        component: () => import("@/views/Surveys/Add.vue"),
      },
      {
        path: "/surveys/detail/:id",
        name: "SurveysDetail",
        meta: { title: "調査票詳細" },
        component: () => import("@/views/Surveys/Detail.vue"),
      },
      {
        path: "/surveys/edit/:id",
        name: "SurveysEdit",
        meta: { title: "調査票編集" },
        component: () => import("@/views/Surveys/Edit.vue"),
      },
      {
        path: "/answers/",
        name: "Answers",
        meta: { title: "回答一覧" },
        component: () => import("@/views/Answers/Index.vue"),
      },
      {
        path: "/answers/detail/:id",
        name: "AnswersDetail",
        meta: { title: "回答詳細" },
        component: () => import("@/views/Answers/Detail.vue"),
      },
      {
        path: "/answers/edit/:id",
        name: "AnswersEdit",
        meta: { title: "回答編集" },
        component: () => import("@/views/Answers/Edit.vue"),
      },
      {
        path: "/answers/edit_publication/:id",
        name: "AnswersEditPublication",
        meta: { title: "回答共有可否編集" },
        component: () => import("@/views/Answers/EditPublication.vue"),
      },
      {
        path: "/publishers/",
        name: "Publishers",
        meta: { title: "版元一覧" },
        component: () => import("@/views/Publishers/Index.vue"),
      },
      {
        path: "/publishers/detail/:id",
        name: "PublishersDetail",
        meta: { title: "版元詳細" },
        component: () => import("@/views/Publishers/Detail.vue"),
      },
      {
        path: "/publishers/add",
        name: "PublishersAdd",
        meta: { title: "版元登録" },
        component: () => import("@/views/Publishers/Add.vue"),
      },
      {
        path: "/publishers/edit/:id",
        name: "PublishersEdit",
        meta: { title: "版元編集" },
        component: () => import("@/views/Publishers/Edit.vue"),
      },
      {
        path: "/results/",
        name: "results",
        meta: { title: "集計結果", role: ["admin"] },
        component: () => import("@/views/Results/Index.vue"),
      },
      {
        path: "/transitions/edit/:id",
        name: "TransitionsEdit",
        meta: { title: "推移データ編集" , role: ["admin"] },
        component: () => import("@/views/Transitions/Edit.vue"),
      },
      {
        path: "/transitions/add/:surveyId",
        name: "TransitionAdd",
        meta: { title: "推移データ登録", role: ["admin"] },
        component: () => import("@/views/Transitions/Add.vue"),
      },
    ],
  },
  {
    path: "/login/",
    name: "Login",
    meta: { title: "ログイン" },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/change_initial_password/",
    name: "ChangeInitialPassword",
    meta: { title: "初期パスワード変更", isPublic: true },
    component: () => import("@/views/ChangeInitialPassword.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //ブラウザ遷移の位置対策
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        top: 0,
      };
    }
  },
});

import { checkAuth, checkAuthByRole, checkEnabled } from "../mixins/auth.js";
router.beforeEach((to, from, next) => {
  //isPublicがtrueの場合には必ずアクセス可能
  //各ルートに meta: { isPublic: true } を追加します。
  if (to.matched.some((page) => page.meta.isPublic)) {
    next();
  } else {
    //async/awaitでそれぞれのログイン状況を取得する
    (async () => {
      let Auth = await checkAuth();
      //ログイン済
      if (Auth) {
        let is_next = true;
        //(種別)
        if (to.meta.role) {
          is_next = false;
          let AuthRole = await checkAuthByRole(to.meta.role);
          if (AuthRole) {
            is_next = true;
          }
        }

        let is_enabled = await checkEnabled();
        if (!is_enabled) {
          next({ path: "/change_initial_password" });
        }

        //権限あり
        if (is_next) {
          next();
        } else {
          if (to.path == "/") {
            next({ path: "/login" });
          } else {
            // 権限のない画面にアクセスしようとした：ひとまずloginに戻す
            next({ path: "/login" });
          }
        }
      }
      //ログインしていない
      else {
        if (to.path.indexOf("/auth") === 0 || to.path.indexOf("/login") === 0) {
          next();
        } else {
          next({ path: "/login" });
        }
      }
    })();
  }
});

export default router;
